<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Name") }}</label>
                  <v-text-field
                    v-model.trim="editedItem.en.name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength50
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.en">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.en.name"
                      :key="index"
                      @focus="onFocus()"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Arabic Name") }}</label>
                  <v-text-field
                    v-model.trim="editedItem.ar.name"
                    @focus="onFocus()"
                    solo
                    :rules="[CheckArabicCharactersOnly(editedItem.ar.name)]"
                  ></v-text-field>
                  <div v-if="validation_errors.ar">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.ar.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Item Type") }}</label>
                  <v-select
                    :items="[
                      { title: $t('Product'), value: 'product' },
                      { title: $t('Service'), value: 'service' }
                    ]"
                    :rules="[validationRules.required]"
                    item-text="title"
                    item-value="value"
                    v-model.trim="editedItem.item_type"
                    @focus="onFocus()"
                    solo
                  >
                  </v-select>

                  <div>
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.item_type"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Category") }}</label>
                  <v-select
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.item_category_id"
                    :rules="[validationRules.required]"
                    @focus="onFocus()"
                    solo
                  >
                  </v-select>
                  <div v-if="validation_errors.item_category_id">
                    <p
                      class="red--text"
                      v-for="(error,
                      index) in validation_errors.item_category_id"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Cost Price") }}</label>
                  <v-text-field
                    v-model.trim="editedItem.cost"
                    :rules="[
                      positiveNumber(editedItem.cost),
                      validationRules.numeric
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.cost">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.cost"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label>{{ $t("Selling Price") }}</label>
                  <v-text-field
                    v-model.trim="editedItem.selling_price"
                    :rules="[
                      positiveNumber(editedItem.selling_price),
                      validationRules.numeric
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.selling_price">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.selling_price"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <label> {{ $t("Allow Discount") }} </label>
                <v-checkbox v-model="editedItem.allow_discount"></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints"
  ],
  name: "AddEditCategoryComponents",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      validation_errors: {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      },
      loading: false,
      categories: []
    };
  },

  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },

  methods: {
    onFocus() {
      this.validation_errors = {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.resetValidation();
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  en: { name: "" },
                  ar: { name: "" },
                  item_type: "",
                  selling_price: "",
                  cost: "",
                  item_category_id: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    // console.log(this.editedItem);
    axios
      .get(this.getApiUrl + "/itemsCategories?select=yes", {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(response => {
        this.categories = response.data.data;
        // console.log(this.categories);
      });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
