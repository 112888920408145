<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12" style="text-align: center">
                <div v-if="errMessage">
                  <p class="red--text">{{ errMessage }}</p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                  dense
                  :disabled="editedItem.confirmed"
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Quarter") }}</label>
                <v-select
                  :items="academicPeriods"
                  item-text="name"
                  item-value="id"
                  v-model.trim="editedItem.academic_year_id"
                  solo
                  dense
                  @focus="onFocus()"
                  :rules="[validationRules.required]"
                  :disabled="editedIndex > -1"
                ></v-select>
                <div v-if="validation_errors.academic_year_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.academic_year_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
                style="padding-right: 0 !important"
              >
                <!-- Subject -->
                <label>{{ $t("Subject") }}</label>
                <v-select
                  :items="subjects"
                  item-text="name"
                  item-value="id"
                  v-model.trim="editedItem.subject_id"
                  :rules="[validationRules.required]"
                  :disabled="editedItem.confirmed || editedIndex > -1"
                  @change="getQualifiersData()"
                  solo
                  dense
                  @focus="onFocus()"
                ></v-select>
                <div v-if="validation_errors.subject_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
                <!-- End Subject -->
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <!-- Qualifier -->
                <label>{{ $t("Qualifiers") }}</label>
                <v-select
                  :items="qualifiers"
                  item-text="name"
                  item-value="id"
                  v-model.trim="editedItem.qualifier_id"
                  :rules="[validationRules.required]"
                  :disabled="editedItem.confirmed || editedIndex > -1"
                  @focus="onFocus()"
                  solo
                  dense
                  @change="getQualifiersMark()"
                ></v-select>
                <div v-if="validation_errors.qualifier_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.qualifier_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>

                <!-- End Qualifier -->
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <!-- Mark -->
                <label
                  >{{ $t("Mark") }}
                  <span v-if="qualifierMark" class="maxSize">
                    {{ $t("Max") }} : {{ qualifierMark }}</span
                  ></label
                >
                <v-text-field
                  v-model="editedItem.mark"
                  :rules="[validationRules.required, validationRules.numeric]"
                  solo
                  dense
                  :disabled="editedItem.confirmed || editedIndex > -1"
                  @focus="onFocus()"
                ></v-text-field>
                <v-col cols="12" sm="12" v-if="marError">
                  <p class="red--text dur-error">
                    Mustn't exceed qualifier mark
                  </p>
                </v-col>
                <div v-if="validation_errors.mark">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.mark"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
                <!-- End Mark -->
              </v-col>

              <!-- students -->
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Students") }}</label>
                <v-select
                  :items="students"
                  item-text="student_full_name"
                  item-value="id"
                  v-model="editedItem.students"
                  @focus="onFocus()"
                  solo
                  dense
                  multiple
                ></v-select>
              </v-col>
              <!-- End students -->

              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Date") }}</label>
                <v-text-field
                  id="exam_date"
                  autocomplete="off"
                  :placeholder="$i18n.t('Enter date')"
                  class="dateIcon"
                  solo
                  dense
                  v-model="editedItem.exam_date"
                  :rules="[validationRules.required]"
                  append-icon="event"
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.exam_date">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.exam_date"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label>{{ $t("Time") }}</label>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="editedItem.time"
                  transition="scale-transition"
                  offset-y
                  max-width="300px"
                  min-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.time"
                      :placeholder="$i18n.t('Enter time')"
                      append-icon="access_time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @keydown.prevent
                      @paste.prevent
                      @drop.prevent
                      @focus="onFocus()"
                      :rules="[validationRules.required]"
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    class="time"
                    v-model="editedItem.time"
                    @click:minute="$refs.menu.save(editedItem.time)"
                  ></v-time-picker>
                </v-menu>

                <div v-if="validation_errors.time">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.time"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    style="padding-right: 0 !important; position: relative"
                  >
                    <label>{{ $t("Duration") }}</label>
                    <v-text-field
                      v-model="editedItem.duration_hr"
                      solo
                      dense
                      min="0"
                      type="number"
                      @focus="onFocus()"
                      @blur="onBlurHr(editedItem.duration_hr)"
                      autocomplete="off"
                      @keyup="
                        validateDuration(
                          editedItem.duration_hr,
                          editedItem.duration_min
                        )
                      "
                      :placeholder="$i18n.t('hr')"
                    ></v-text-field>

                    <div v-if="validation_errors.duration_hr">
                      <p
                        class="red--text"
                        v-for="(error, index) in validation_errors.duration_hr"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    style="padding-right: 0 !important; position: relative"
                  >
                    <label style="visibility: hidden">{{
                      $t("Duration")
                    }}</label>
                    <v-text-field
                      v-model="editedItem.duration_min"
                      autocomplete="off"
                      solo
                      dense
                      type="number"
                      max="60"
                      min="0"
                      @focus="onFocus()"
                      @blur="onBlurMin(editedItem.duration_min)"
                      @keyup="
                        validateDuration(
                          editedItem.duration_hr,
                          editedItem.duration_min
                        )
                      "
                      :placeholder="$i18n.t('min')"
                    ></v-text-field>
                    <span class="betweencolon">:</span>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <p class="red--text dur-error">{{ validationMsg }}</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="editedItem.online_exam"
                  @change="hideCheckboxs()"
                  :label="$i18n.t('Create online exam')"
                  :disabled="editedIndex > -1"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="editedItem.auto_correction"
                  v-if="showCheckboxs"
                  :label="$t('Auto Correction')"
                  :disabled="editedIndex > -1"
                ></v-checkbox>
                <div
                  v-if="editedItem.auto_correction && editedItem.online_exam"
                >
                  <p class="red--text">
                    {{
                      $t("Essay questions won't be available for this option")
                    }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <!-- options -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-if="showCheckboxs"
                  v-model="editedItem.randomiz"
                  :label="$t('Randomize Questions Order')"
                  :disabled="editedIndex > -1"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  mixins: [validationMixin],
  name: "AddEditExamComponent",
  data() {
    return {
      menu2: false,
      valid: true,
      validationMsg: "",
      durationValid: false,
      validation_errors: {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        duration_hr: "",
      },
      loading: false,
      marError: false,
      errMessage: "",
      qualifiers: [],
      academicPeriods: [],
      subjects: [],
      students: [],
      qualifierMark: "",
      showCheckboxs: true,
    };
  },
  watch: {
    // "editedItem.subject_id": {
    //   handler() {
    //     this.editedItem.qualifier_id = "";
    //   }
    // },
    AddEditdialog: {
      handler() {
        if (this.editedIndex == -1) {
          this.editedItem.online_exam = true;
        }
        if (this.editedIndex > -1 && this.AddEditdialog == true) {
          this.getQualifiersData();
          this.getQualifiersMark();
        }
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
    editedIndex: function (value) {
      if (value != -1) {
        this.getQualifiers();
      } else if (value == -1) {
        this.qualifiers = [];
      }
    },
  },
  methods: {
    hideCheckboxs() {
      if (this.editedItem.online_exam) {
        this.showCheckboxs = true;
      } else {
        this.showCheckboxs = false;
      }
    },
    validateDuration(hr, min) {
      if (!hr && !min) {
        this.validationMsg = this.$i18n.t("This field is required");
        this.durationValid = false;
      } else if (hr && isNaN(hr)) {
        this.validationMsg = this.$i18n.t("Hr should be number");
        this.durationValid = false;
      } else if (min && isNaN(min)) {
        this.validationMsg = this.$i18n.t("min should be number");
        this.durationValid = false;
      } else if (min >= 60) {
        // this.validationMsg = "min should be less than 60";
        this.editedItem.duration_min = "";
        this.durationValid = false;
      } else {
        this.validationMsg = "";
        this.durationValid = true;
      }
    },
    onBlurMin(min) {
      if (min == "" || !min) {
        this.editedItem.duration_min = "00";
      }
      if (!isNaN(min) && min < 10 && !min.startsWith("0")) {
        this.editedItem.duration_min = "0" + min;
      }
    },
    onBlurHr(hr) {
      if (hr == "" || !hr) {
        this.editedItem.duration_hr = "00";
      }
      if (!isNaN(hr) && hr < 10 && !hr.startsWith("0")) {
        this.editedItem.duration_hr = "0" + hr;
      }
    },
    onFocus() {
      this.validation_errors = {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        randomiz: "",
        duration_hr: "",
      };
    },
    close() {
      this.AddEditdialog = false;
      this.editedItem = {};
      this.validation_errors = {
        name: "",
        subject_id: "",
        qualifier_id: "",
        mark: "",
        exam_date: "",
        academic_year_id: "",
        randomiz: "",
        duration_hr: "",
      };
      this.qualifiers = [];
      window.location.reload();

      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.editedItem.duration_min == "" || !this.editedItem.duration_min) {
        this.editedItem.duration_min = 0;
      }
      if (this.editedItem.duration_hr == "" || !this.editedItem.duration_hr) {
        this.editedItem.duration_hr = 0;
      }
      // mahmoud kamal edit
      if (
        Number(this.editedItem.mark) > Number(this.qualifierMark) &&
        this.editedIndex == -1
      ) {
        this.marError = true;
      } else if (this.valid == false && this.durationValid == false) {
        this.validate();
        this.validateDuration(
          this.editedItem.duration_hr,
          this.editedItem.duration_min
        );
      } else {
        this.editedItem.class_id = this.$router.currentRoute.params.classId;
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              this.loading = false;
              if (response.data.status.error == true) {
                Object(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
                if (response.data.status.validation_errors.length == 0) {
                  this.errMessage = response.data.status.message;
                }
              } else {
                this.AddEditdialog = false;
                let data = response.data.data;
                let classId = response.data.data.class_id;
                let examId = response.data.data.id;

                this.$emit("childToParent", false, null, this.editedIndex);
                if (data.online_exam) {
                  this.$router.push(
                    {
                      path: "/" + "createOnlineExam/" + examId,
                    },
                    () => {}
                  );
                }
                this.qualifiers = [];
                // this.close();
                // this.qualifiers = [];
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
                if (response.data.status.validation_errors.length == 0) {
                  this.errMessage = response.data.status.message;
                }
              } else {
                this.disabledSubmitbtn = true;
                // let online_exam = this.editedItem.online_exam;
                this.editedItem = {
                  name: "",
                  subject_id: "",
                  qualifier_id: "",
                  mark: "",
                  exam_date: "",
                  academic_year_id: "",
                  duration_hr: "",
                  duration_min: "",
                  online_exam: false,
                };
                let data = response.data.data;
                // let classId = response.data.data.class_id;
                let examId = response.data.data.id;
                this.$emit("childToParent", false, null, null);
                if (data.online_exam == true || data.online_exam == "true") {
                  this.$router.push(
                    {
                      path:
                        "/" + "createOnlineExam/" + examId + "?tab=" + this.tab,
                    },
                    () => {}
                  );
                }
                this.qualifiers = [];
                // this.close();
              }

              this.loading = false;
            });
        }
        this.validation_errors = {
          name: "",
          subject_id: "",
          qualifier_id: "",
          mark: "",
          exam_date: "",
          academic_year_id: "",
        };
      }
    },
    getQualifiers() {
      if (this.editedItem.subject_id) {
        axios
          .get(
            this.getApiUrl +
              "/exams/getQualifiers/" +
              this.editedItem.subject_id +
              "/" +
              this.academicId,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.qualifiers = response.data.data;
          });
      }
    },
    getQualifiersMark() {
      let qualifier_id = this.editedItem.qualifier_id;

      axios
        .get(
          this.getApiUrl +
            "/qualifier/getQualifierMark/" +
            this.editedItem.subject_id +
            "/" +
            qualifier_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.qualifierMark = response.data.data;
        });
    },
    getQualifiersData() {
      // this.editedItem.qualifier_id = "";
      let classId = this.$route.params.classId;
      axios
        .get(
          this.getApiUrl + "/exams/getQualifiers/" + this.editedItem.subject_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.qualifiers = response.data.data;
          this.qualifierMark = "";
        });
    },
    getClassStudents() {
      axios
        .get(
          this.getApiUrl +
            "/students/listStudentClasses/" +
            this.classId +
            "?all_data&only_assigned",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.students = response.data.data.students;
        });
    },
    // getSubjectbasedOnQuarter(quarterId) {
    //   let academic_grade_id = this.$route.params.academic_grade_id;
    //   axios
    //     .get(
    //       this.getApiUrl +
    //         "/educational-history/filter/subjects/" +
    //         quarterId +
    //         "?academic_grade_id=" +
    //         academic_grade_id,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.subjects = response.data.data;
    //     });
    // },
  },

  mounted() {
    this.academicId = this.$router.currentRoute.params.academicYear;
    this.classId = this.$router.currentRoute.params.classId;
    this.getClassStudents();
    // this.editedItem.class_id = this.classId;

    axios
      .get(
        this.getApiUrl +
          "/exams/getAcademic_periods/" +
          this.academicId +
          "/" +
          this.$route.params.classId,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => {
        this.academicPeriods = response.data.data.quarter;
        this.subjects = response.data.data.subjects;
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-name {
  margin-top: -15px !important;
}

.betweencolon {
  position: absolute;
  top: 3rem;
  left: 5%;
}

.dur-error {
  margin-top: 0 !important;
}
.maxSize {
  font-size: 10px;
}
</style>
