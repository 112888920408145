<template>
  <v-dialog v-model="PackageDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Add Package") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Package") }} </label>
                <v-autocomplete
                  :items="packages"
                  v-model="package_id"
                  item-text="name"
                  item-value="id"
                  :rules="[validationRules.required]"
                  @focus="onFocus()"
                  solo
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: ["student_id", "PackageDialog"],
  mixins: [validationMixin],
  name: "AddStudentPackageComponent",
  data() {
    return {
      packages: [],
      valid: false,
      loading: false,
      disabledSubmitbtn: false,
      package_id: ""
    };
  },
  watch: {
    PackageDialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  methods: {
    close() {
      this.PackageDialog = false;
      this.data = [];
      this.$emit("childToParent", false);
    },
    onFocus() {
      this.resetValidation();
    },
    getPackages() {
      axios
        .get(
          this.getApiUrl + "/discount_packages/all_packages",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.packages = response.data.data;
        });
    },
    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;

        axios
          .post(
            this.getApiUrl +
              "/students/assignPackageToStudent/" +
              this.student_id,
            { package_id: this.package_id },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              this.loading = false;
              this.disabledSubmitbtn = true;
              this.data = [];
              this.$emit("childToParent", false);
              this.close();
            }
          });
      }
    }
  },
  mounted() {
    this.getPackages();
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.space-name {
  margin-top: -15px !important;
}
</style>
