<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div class="form-group">
                  <label>{{ $t("Name") }}</label>
                  <v-text-field
                    v-model="editedItem.en.name"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength50
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.en.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.en.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div class="form-group">
                  <label>{{ $t("Arabic Name") }}</label>
                  <v-text-field
                    v-model="editedItem.ar.name"
                    :rules="[
                      CheckArabicCharactersOnly(editedItem.ar.name),
                      validationRules.maxLength50
                    ]"
                    @focus="onFocus()"
                    solo
                  ></v-text-field>
                  <div v-if="validation_errors.ar.name">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.ar.name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <div class="form-group">
                  <label>{{ $t("Notes") }}</label>
                  <v-textarea
                    v-model="editedItem.notes"
                    rows="3"
                    solo
                  ></v-textarea>
                  <div v-if="validation_errors.notes">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.notes"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div class="form-group">
                  <label> {{ $t("Allow VAT") }} </label>
                  <v-checkbox v-model="editedItem.allow_vat"></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel " @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints"
  ],
  mixins: [validationMixin],
  name: "AddEditCategoryComponents",
  data() {
    return {
      valid: true,
      validation_errors: {
        en: {
          name: ""
        },
        ar: {
          name: ""
        },
        notes: ""
      },
      loading: false
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        en: {
          name: ""
        },
        ar: {
          name: ""
        },
        notes: ""
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        en: {
          name: ""
        },
        ar: {
          name: ""
        },
        notes: ""
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.resetValidation();
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  name: "",
                  notes: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
