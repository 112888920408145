<template>
  <div class="library">
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Items Setup</h3>
      <ul>
        <li>
          <a href="index.html">Items</a>
        </li>
        <li>All Items</li>
      </ul>
    </div>
    <div> -->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import ACL from "../../acl";
import axios from "axios";
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: ACL.Role("Student") ? "My Library" : "Library",
        path: "digital_library",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "filename",
            align: "start",
            sortable: true,
          },
          {
            text: this.$i18n.t("Type"),
            value: "Type",
            sortable: true,
          },
          {
            text: this.$i18n.t("Category"),
            value: "category",
            sortable: true,
          },
          {
            text: this.$i18n.t("Grade"),
            value: "grade",
            sortable: true,
          },
          {
            text: this.$i18n.t("Subject"),
            value: "subject",
            sortable: true,
          },
          {
            text: this.$i18n.t("Date Uploaded"),
            value: "date",
            sortable:true,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Digital Library"),
        apiEndPoints: {
          // list: "elibrary",

          list: "elibrary",
          create: "elibrary/store",
          edit: "elibrary/update",
          delete: "elibrary/destroy",
        },
        editedItem: {
          filename: "",
          type: "",
          category_id: "",
          grade_id: "",
          subject_id: "",
          class_ids: [],
        },
        permissions: {
          list: "show-library",
          add: "add-library",
          edit: "edit-library",
          delete: "delete-library",
          list_category: ACL.checkPermission("list-library-category"),
          addCategory: ACL.checkPermission("add-library-category"),
          editCategory: ACL.checkPermission("edit-library-category"),
          deleteCategory: ACL.checkPermission("delete-library-category"),
        },
        filters: {
          categories: [],
          grades: [],
          subjects: [],
          types: [],
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/elibrary/categories?all=all", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table.filters.categories = response.data.data;
      });

    axios
      .get(this.getApiUrl + "/elibrary/getSubjectsUnderGrade", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table.filters.subjects = response.data.data.subjects;
      });

    axios
      .get(this.getApiUrl + "/elibrary/getGradesByFilter", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table.filters.grades = response.data.data;
      });
  },
};
</script>

<style></style>
