var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength50,
                ],"solo":""},model:{value:(_vm.editedItem.filename),callback:function ($$v) {_vm.$set(_vm.editedItem, "filename", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.filename"}}),(_vm.validation_errors.filename)?_c('div',_vm._l((_vm.validation_errors.filename),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("Type")))]),_c('v-select',{attrs:{"items":[
                    { id: 'file', name: _vm.$i18n.t('File') },
                    { id: 'url', name: _vm.$i18n.t('Url') },
                  ],"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"solo":""},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.type"}})],1),(_vm.validation_errors.subject_id)?_c('div',_vm._l((_vm.validation_errors.subject_id),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("Category")))]),_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"solo":""},model:{value:(_vm.editedItem.category_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "category_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.category_id"}})],1),(_vm.categoryValidation)?_c('div',[_c('p',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("This field is required"))+" ")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("Grade")))]),_c('v-select',{attrs:{"items":_vm.grades,"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"solo":""},on:{"change":function($event){_vm.editedItem.class_ids = [];
                    _vm.getSubjectsUnderGrade;}},model:{value:(_vm.editedItem.grade_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "grade_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.grade_id"}})],1),(_vm.validation_errors.grade_id)?_c('div',_vm._l((_vm.validation_errors.grade_id),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("Classes")))]),_c('v-select',{attrs:{"items":_vm.classes,"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"multiple":"","solo":""},model:{value:(_vm.editedItem.class_ids),callback:function ($$v) {_vm.$set(_vm.editedItem, "class_ids", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.class_ids"}})],1),(_vm.calssesValidation)?_c('div',[_c('p',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("This field is required"))+" ")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t("Subject")))]),_c('v-select',{attrs:{"items":_vm.subjects,"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"solo":""},model:{value:(_vm.editedItem.subject_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "subject_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.subject_id"}})],1),(_vm.validation_errors.subject_id)?_c('div',_vm._l((_vm.validation_errors.subject_id),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]),(_vm.editedItem.type == 'file')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.fileselect)?_c('v-chip',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.fileselect)+" ")]):_vm._e(),(
                  _vm.editedIndex > -1 &&
                  _vm.fileselect == '' &&
                  _vm.editedItem.file_type == 'Image'
                )?_c('img',{attrs:{"src":_vm.editedItem.download_url,"width":"50px","height":"50px","alt":""}}):_vm._e(),(
                  _vm.editedIndex > -1 &&
                  _vm.fileselect == '' &&
                  _vm.editedItem.file_type != 'Image'
                )?_c('a',{attrs:{"href":_vm.editedItem.download_url,"target":"_blank"}},[_vm._v("attached File")]):_vm._e(),_c('input',{ref:"file",staticStyle:{"display":"none !important"},attrs:{"id":"myFileBrowser","type":"File","rules":_vm.editedIndex == -1 ? [_vm.validationRules.required] : []},on:{"change":function($event){return _vm.readURL()}}}),_c('label',{staticClass:"btn-attach-files",on:{"click":function($event){return _vm.openFileInput()}}},[_vm._v(_vm._s(_vm.$t("Attach File(s)")))]),(_vm.validation_errors.file)?_c('div',_vm._l((_vm.validation_errors.file),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1):_vm._e(),(_vm.editedItem.type == 'url')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.$t("Enter Url")))]),_c('v-text-field',{attrs:{"rules":[_vm.validationRules.required],"solo":""},model:{value:(_vm.editedItem.url),callback:function ($$v) {_vm.$set(_vm.editedItem, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.url"}})],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel cancelBtn",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save saveBtn",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackTime,"color":_vm.snackColor,"multi-line":true,"right":true,"top":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.errormsg)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }