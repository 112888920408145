var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.maxLength50
                  ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.en.name),callback:function ($$v) {_vm.$set(_vm.editedItem.en, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.en.name"}}),(_vm.validation_errors.en)?_c('div',_vm._l((_vm.validation_errors.en.name),function(error,index){return _c('p',{key:index,staticClass:"red--text",on:{"focus":function($event){return _vm.onFocus()}}},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Arabic Name")))]),_c('v-text-field',{attrs:{"solo":"","rules":[_vm.CheckArabicCharactersOnly(_vm.editedItem.ar.name)]},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.ar.name),callback:function ($$v) {_vm.$set(_vm.editedItem.ar, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.ar.name"}}),(_vm.validation_errors.ar)?_c('div',_vm._l((_vm.validation_errors.ar.name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Item Type")))]),_c('v-select',{attrs:{"items":[
                    { title: _vm.$t('Product'), value: 'product' },
                    { title: _vm.$t('Service'), value: 'service' }
                  ],"rules":[_vm.validationRules.required],"item-text":"title","item-value":"value","solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.item_type),callback:function ($$v) {_vm.$set(_vm.editedItem, "item_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.item_type"}}),_c('div',_vm._l((_vm.validation_errors.item_type),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Category")))]),_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","rules":[_vm.validationRules.required],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.item_category_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "item_category_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.item_category_id"}}),(_vm.validation_errors.item_category_id)?_c('div',_vm._l((_vm.validation_errors.item_category_id),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Cost Price")))]),_c('v-text-field',{attrs:{"rules":[
                    _vm.positiveNumber(_vm.editedItem.cost),
                    _vm.validationRules.numeric
                  ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.cost),callback:function ($$v) {_vm.$set(_vm.editedItem, "cost", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.cost"}}),(_vm.validation_errors.cost)?_c('div',_vm._l((_vm.validation_errors.cost),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Selling Price")))]),_c('v-text-field',{attrs:{"rules":[
                    _vm.positiveNumber(_vm.editedItem.selling_price),
                    _vm.validationRules.numeric
                  ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.selling_price),callback:function ($$v) {_vm.$set(_vm.editedItem, "selling_price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.selling_price"}}),(_vm.validation_errors.selling_price)?_c('div',_vm._l((_vm.validation_errors.selling_price),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("Allow Discount"))+" ")]),_c('v-checkbox',{model:{value:(_vm.editedItem.allow_discount),callback:function ($$v) {_vm.$set(_vm.editedItem, "allow_discount", $$v)},expression:"editedItem.allow_discount"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }